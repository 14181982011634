export const FLAGS = {
  D2C_CHILD_BOOKING_FLOW: 'd2c-child-booking-flow',
  SHOW_WEIGHT_LOSS_ACCORDION: 'show-weight-loss-accordion',
  UPDATE_LAB_PRICING: 'update-lab-pricing',
  SENTRY_FEEDBACK_WIDGET: 'sentry-feedback-widget',
  APPOINTMENTS_BY_DOCTOR: 'appointment-times-by-doctor',
  MONEY_BACK_GUARANTEE_STYLE_UPDATE: 'money-back-guarantee-style-update',
  PREFERRED_NAME_STATUS: 'preferred_name_prefill_blank',
  SHOW_THERAPY_EMERGENCY_TRIAGE: 'show_therapy_flow_questionnaire',
  HARD_MEMBERSHIP: 'hard-membership',
  PATIENT_BILLING_NOTICE_UPDATES: 'patient-billing-notice-updates',
  OON_MODAL_UPDATES: 'oon-modal-updates',
  MEDICARE_UPDATES: 'medicare_updates',
  DISABLE_DEPENDENT_INSURANCE: 'disable-dependent-insurance',
  PROFILE_PAGE_MULTIPLE_COVERAGES: 'profile-page-multiple-coverages',
  CUTOFF_TIME_READ_TIMEOUT_BLUESHIELD_IN_HOURS: 'cutoff_time_read_timeout_blueshield_in_hours',
  NEW_PHARMACY: 'new_pharmacy',
  ACCOLADEX_PHARMACY: 'accoladex_pharmacy',
  AT_HOME_LABS: 'enable_at_home_metabolic_labs',
  ANNUAL_WELLNESS_VISIT_CHECKOUT: 'annual_wellness_visit_checkout',
  MEDICARE_FORM_FIELD: 'medicare-form-field',
  MEDICARE_ABN: 'medicare_abn',
  MESSAGING_WEB_ENABLED: 'messaging-web-enabled',
  CONTINUE_BOOKING_WITH_EST_PRICE: 'continue_booking_with_est_price',
  WM_ONBOARDING_EXPERIMENT: 'wm_onboarding_experiment',
  PASSWORDLESS_AUTH: 'passwordless_auth',
  SELECT_MEMBERSHIP_PLAN: 'select_membership_plan',
  PASSWORDLESS_REGISTRATION: 'passwordless_registration',
  REMOVE_SECONDARY_PLAN_ADDITION_MEDICARE: 'remove_secondary_plan_addition_medicare',
  EXPIRED_APPOINTMENT_RECOVERY_MODAL: 'expired_appointment_recovery_modal',
};
